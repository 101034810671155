import { required, minNumber } from "@rxweb/reactive-forms";
import MultipleMultiselectItem from './MultipleMultiselectItem';
import TaggingMultiselectItem from './TaggingMultiselectItem';

export default class CanzoneFormItem {
    private _titolo: string = "";
    private _autore: string = "";
    private _partiDellaMessaValueList: MultipleMultiselectItem[] = [];
    //private _tempiLiturgiciValueList: MultipleMultiselectItem[] = [];
    //private _paroleChiaveValueList: TaggingMultiselectItem[] = [];
    
    @required()
    set titolo(value: string) {
        this._titolo = value;
    }

    get titolo(): string {
        return this._titolo;
    }
        
    set autore(value: string) {
        this._autore = value;
    }

    get autore(): string {
        return this._autore;
    }

    @required()
    set partiDellaMessaValueList(value: MultipleMultiselectItem[]) {
        this._partiDellaMessaValueList = value;
    }

    get partiDellaMessaValueList(): MultipleMultiselectItem[] {
        return this._partiDellaMessaValueList;
    }

    //@required()
    //set tempiLiturgiciValueList(value: MultipleMultiselectItem[]) {        
    //    this._tempiLiturgiciValueList = value;
    //}

    //get tempiLiturgiciValueList(): MultipleMultiselectItem[] {
    //    return this._tempiLiturgiciValueList;
    //}

    //@required()
    //set paroleChiaveValueList(value: TaggingMultiselectItem[]) {        
    //    this._paroleChiaveValueList = value;
    //}

    //get paroleChiaveValueList(): TaggingMultiselectItem[] {
    //    return this._paroleChiaveValueList;
    //}

    //@minNumber({ value: 1 }) 
    //set partiDellaMessaValueLength(value: number) {
    //    this._partiDellaMessaValueLength = value;
    //}

    //get partiDellaMessaValueLength(): number {
    //    return this._partiDellaMessaValueLength;
    //}



}