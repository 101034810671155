

































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
//import { required, minLength, between } from 'vuelidate/lib/validators'
import { RxFormBuilder, IFormGroup } from "@rxweb/reactive-forms"
import { alpha, required, minLength } from "@rxweb/reactive-forms";
import { serialize } from 'object-to-formdata';
// Advanced Use - Hook into Quill's API for Custom Functionality
import { VueEditor } from "vue2-editor";
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import VueDocumentEditor from 'vue-document-editor'
import SingleMultiselect from './SingleMultiselect.vue';
import MultipleMultiselect from './MultipleMultiselect.vue';
import TaggingMultiselect from './TaggingMultiselect.vue';
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';
import TaggingMultiselectItem from '../model/TaggingMultiselectItem';
import CanzoneFormItem from '../model/CanzoneFormItem';
import EditorCanzone from './EditorCanzone.vue';
import { TipoFile } from "../utils/enum";
import { Utils } from "../utils/utils";
import ParteDellaMessaDataService from "../services/ParteDellaMessaDataService";
import TempoLiturgicoDataService from "../services/TempoLiturgicoDataService";
import ParolaChiaveDataService from "../services/ParolaChiaveDataService";
import CanzoneDataService from "../services/CanzoneDataService";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({
    components: {
        SingleMultiselect,
        MultipleMultiselect,
        TaggingMultiselect,
        VueEditor,
        VueDocumentEditor,
        VueFileToolbarMenu,
        EditorCanzone
    },
})
export default class FormCanzone extends Vue {
    @Prop({ required: false }) readonly canzone!: any;
    @Prop({ required: false }) readonly readonly!: boolean;
    @Prop({ required: false }) readonly editoronly!: boolean;
    @Prop({ required: false }) readonly allPartiDellaMessa!: MultipleMultiselectItem[];
    @Prop({ required: false }) readonly allTempiLiturgici!: MultipleMultiselectItem[];
    @Prop({ required: false }) readonly allParoleChiave!: TaggingMultiselectItem[];

    @Auth.State("user")
    private currentUser!: any;

    @Auth.State("nameidentifier")
    private userId!: any;

    @Auth.State("status")
    private status!: any;

    @Auth.Action
    public signOut!: () => void;


    get IsLogged() {
        return this.status.loggedIn;
    }

    isOwner(userId: any) {
        return this.IsLogged && this.userId == userId;
    }

    get disabledForm() {
        return this.readonly || !this.IsLogged || (typeof this.canzone != "undefined" && !this.isOwner(this.canzone.idUtenteCreazione));
    }

    get canDownloadFile() {
        return this.readonly;
    }

    get canModificaCanzone() {
        return this.readonly && this.IsLogged && !this.editoronly && (typeof this.canzone != "undefined" && this.isOwner(this.canzone?.idUtenteCreazione));
    }

    get mostraAllegati() {
        //console.log(this.allegati);
        return !this.disabledForm || this.allegatiComputed.length > 0;
    }

    canPlayFile(allegato: any) {
        return this.readonly && allegato.tipoFile == TipoFile.Audio;
    }

    private visibilitaOptionList: any = [{ value: 1, text: 'Privata' }, { value: 2, text: 'Pubblica' }];
    private _visibilitaSelected: any = null;
    get visibilitaSelected() {
        if (this._visibilitaSelected == null)
            this._visibilitaSelected = typeof this.canzone != "undefined" && typeof this.canzone.visibilityPolicy != "undefined" && this.canzone.visibilityPolicy != null ? this.canzone.visibilityPolicy : 1;
        //console.log(this._visibilitaSelected);
        return this._visibilitaSelected;
    }
    set visibilitaSelected(value: any) {
        this._visibilitaSelected = value;
    }

    private scrollToUp = {
        el: '#app',
        container: "body",
        duration: 500,
        easing: "ease",
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    };

    getscrolltoUp() {
        return "#".concat(this.getFormCanzoneContainerId());
    }

    getFormCanzoneContainerId() {
        if (typeof this.canzone != "undefined") {
            return "FormCanzone" + this.canzone.id;
        }
        return "FormCanzone";
    }
    

    canzoneFormGroup!: IFormGroup<CanzoneFormItem>;
    formBuilder: RxFormBuilder = new RxFormBuilder();

    constructor() {
        super();
        this.canzoneFormGroup = this.formBuilder.formGroup(CanzoneFormItem) as IFormGroup<CanzoneFormItem>;
    }

    private feedback: string = "";
    private feedbackshow: boolean = false;
    private feedbackAlertType: string = "";

    private feedbackPanelFileUpload: string = "";
    private feedbackPanelFileUploadshow: boolean = false;
    private feedbackPanelFileUploadAlertType: string = "";


    private partiDellaMessaList: MultipleMultiselectItem[] = [];
    private tempiLiturgiciList: MultipleMultiselectItem[] = [];
    private paroleChiaveList: TaggingMultiselectItem[] = [];


    get testoCanzoneComputed() {
        return this.testoCanzone;
    }
    set testoCanzoneComputed(newValue: string) {
        if (newValue != null) {
            this.testoCanzone = newValue;
        }
    }

    get idCanzoneComputed() {
        return this.idCanzone;
    }
    set idCanzoneComputed(newValue: string) {
        if (newValue != null) {
            this.idCanzone = newValue;
        }
    }

    private editorCanzoneName: string = "editorcanzone";
    private testoCanzone: string = "";
    private idCanzone: string = "";

    private allegati: any = [];
    private allegato: any;

    private files: any = [];

    validateAllegato(newValue: any) {
        if (newValue != null) {
            if (newValue.size > 10000000) {
                this.feedbackPanelFileUploadshow = true;
                this.feedbackPanelFileUploadAlertType = "danger";
                this.feedbackPanelFileUpload = "dimensioni massime allegati: 10000 KB";
                return false;
            }
            return true;
        }
        else return null;
    }

    get allegatiComputed() {
        //console.log("get allegatiComputed");
        return this.allegati;
    }
    set allegatiComputed(newValue: any) {
        //console.log("set allegatiComputed");
        if (newValue != null) {
            this.allegati = newValue;
        }
    }

    get allegatoComputed() {
        return this.allegato;
    }
    set allegatoComputed(newValue: any) {
        if (newValue != null) {
            //console.log(newValue);
            if (this.validateAllegato(newValue) == true) {
                newValue.guid = Utils.createGuid();
                this.files.push(newValue);
                this.allegato = {
                    id: 0,
                    guid: newValue.guid,
                    nome: newValue.name,
                    estensione: newValue.name.split('.').pop(),
                    dimensione: newValue.size,
                    type: newValue.type,
                    tipoFile: newValue.type.match(/audio.*/) ? TipoFile.Audio : TipoFile.Documento
                };
                this.allegatiComputed.push(this.allegato);
            }
        }
    }
        

    getAllegatiCanzoneAndAction(id: string, action: any) {
        CanzoneDataService.get(id, true)
            .then((response) => {
                this.allegatiComputed = response.data.allegati;
                action();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    private partiDellaMessaValueList: any = null;
    private tempiLiturgiciValueList: any = null;
    private paroleChiaveValueList: any = null;

    getPartiDellaMessa() {
        //console.log(this.canzone);
        var partidellamessa: MultipleMultiselectItem[] = [];
        if (typeof this.canzone != "undefined" && this.canzone.partiDellaMessaIdeali != null && Array.isArray(this.canzone.partiDellaMessaIdeali)) {
            this.canzone.partiDellaMessaIdeali.forEach(function (value: any) {
                //console.log(value);
                partidellamessa.push(new MultipleMultiselectItem(value.nome, value.id))
                //console.log("partidellamessa:");
                //console.log(partidellamessa);
            });
        }
        this.partiDellaMessaValueList = partidellamessa;
    }

    getTempiLiturgiciValueList() {
        //console.log(this.canzone);
        var tempiliturgici: MultipleMultiselectItem[] = [];
        if (typeof this.canzone != "undefined" && this.canzone.tempiLiturgiciIdeali != null && Array.isArray(this.canzone.tempiLiturgiciIdeali)) {
            this.canzone.tempiLiturgiciIdeali.forEach(function (value: any) {
                //console.log(value);
                tempiliturgici.push(new MultipleMultiselectItem(value.nome, value.id))
                //console.log(tempiliturgici);
            });
        }
        this.tempiLiturgiciValueList = tempiliturgici;
    }

    getParoleChiaveValueList() {
        //console.log(this.canzone);
        var parolechiave: TaggingMultiselectItem[] = [];
        if (typeof this.canzone != "undefined" && this.canzone.paroleChiave != null && Array.isArray(this.canzone.paroleChiave)) {
            this.canzone.paroleChiave.forEach(function (value: any) {
                //console.log(value);
                parolechiave.push(new TaggingMultiselectItem(value.nome))
                //console.log(tempiliturgici);
            });
        }
        this.paroleChiaveValueList = parolechiave;
    }

    getAllPartiDellaMessa() {
        ParteDellaMessaDataService.getAll()
            .then((response) => {
                var partidellamessa: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    partidellamessa.push(new MultipleMultiselectItem(value.nome, value.id))

                });
                //console.log(partidellamessa);
                this.partiDellaMessaList = partidellamessa;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAllTempiLiturgici() {
        TempoLiturgicoDataService.getAll()
            .then((response) => {
                var tempoLiturgico: MultipleMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    tempoLiturgico.push(new MultipleMultiselectItem(value.nome, value.id))

                });
                //console.log(tempoLiturgico);
                this.tempiLiturgiciList = tempoLiturgico;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getAllegatiValueList() {
        if (typeof this.canzone != "undefined") {
            this.allegatiComputed = this.canzone.allegati
        }
    }

    getAllParoleChiave() {
        ParolaChiaveDataService.getAll()
            .then((response) => {
                var parolaChiave: TaggingMultiselectItem[] = [];
                response.data.forEach(function (value: any) {
                    parolaChiave.push(new TaggingMultiselectItem(value.nome))

                });
                //console.log(parolaChiave);
                this.paroleChiaveList = parolaChiave;
            })
            .catch((e) => {
                console.log(e);
            });
    }

    changedPartiDellaMessaSelection(partiDellaMessaSelezionate: MultipleMultiselectItem[]) {
        //console.log("changedPartiDellaMessaSelection form canzone");
        //console.log(partiDellaMessaSelezionate);
        this.partiDellaMessaValueList = partiDellaMessaSelezionate;
    }

    changedTempiLiturgiciSelection(tempiLiturgiciSelezionati: MultipleMultiselectItem[]) {
        //console.log("changedTempiLiturgiciSelection form canzone");
        //console.log(tempiLiturgiciSelezionati);
        this.tempiLiturgiciValueList = tempiLiturgiciSelezionati;
    }

    changedParoleChiaveSelection(paroleChiaveSelezionate: TaggingMultiselectItem[]) {
        //console.log("changedParoleChiaveSelection form canzone");
        //console.log(paroleChiaveSelezionate);
        this.paroleChiaveValueList = paroleChiaveSelezionate;
    }
    
    downloadFile(allegato: any) {
        //console.log(allegato);
        if ((allegato.stream == null || allegato.stream == "") && this.canzone?.id != null && this.canzone.id > 0) {
            allegato.downloading = true;
            //console.log(allegato.loading);
            this.getAllegatiCanzoneAndAction(this.canzone.id, () => {
                Utils.downloadFile(this.allegatiComputed.find((a: any) => a.id == allegato.id));
                allegato.downloading = false;

                //console.log(allegato.loading);
            })
        }
        else {
            Utils.downloadFile(allegato);
        }
        //serve per far aggiornare la lista di allegati ed aggiornare la pagina
        this.allegatiComputed.splice();
    }

    deleteFile(index: string) {
        var allegato = this.allegati[index];
        //console.log(allegato);
        if (allegato.guid != null) {
            this.files.splice(this.files.findIndex((f: any) => f.guid = allegato.guid), 1)
        }
        this.allegatiComputed.splice(index, 1);
    }

    playFile(index: string) {
        var allegato = this.allegati[index];
        if ((allegato.stream == null || allegato.stream == "") && this.canzone?.id != null && this.canzone.id > 0) {
            allegato.playloading = true;
            this.getAllegatiCanzoneAndAction(this.canzone.id, () => {
                Utils.playFile(this.allegatiComputed.find((a: any) => a.id == allegato.id));
                allegato.playloading = false;
            })
        }
        else {
            Utils.playFile(allegato);
        }
        //serve per far aggiornare la lista di allegati ed aggiornare la pagina
        this.allegatiComputed.splice();
    }

    pauseFile(index: string) {
        var allegato = this.allegati[index];
        Utils.pauseFile(allegato);
        //serve per far aggiornare la lista di allegati ed aggiornare la pagina
        this.allegatiComputed.splice();
    }

    goToModificaCanzone() {
        this.$router.push('/modificacanzone/' + this.canzone.id);
    }

    saveCanzone() {

        const options = {
            /**
             * include array indices in FormData keys
             * defaults to false
             */
            indices: true,

            /**
             * treat null values like undefined values and ignore them
             * defaults to false
             */
            nullsAsUndefineds: false,

            /**
             * convert true or false to 1 or 0 respectively
             * defaults to false
             */
            booleansAsIntegers: false,

            /**
             * store arrays even if they're empty
             * defaults to false
             */
            allowEmptyArrays: true,
        };

        if (typeof this.canzone == "undefined") {

            var data = {
                titolo: this.canzoneFormGroup.props.titolo,
                autore: this.canzoneFormGroup.props.autore,
                testo: (this.$refs[this.editorCanzoneName] as any).getTestoCanzone(),
                partiDellaMessaIdeali: this.partiDellaMessaValueList,
                tempiLiturgiciIdeali: this.tempiLiturgiciValueList,
                paroleChiave: this.paroleChiaveValueList,
                allegati: this.allegatiComputed,
                visibilityPolicy: this._visibilitaSelected
                //files: this.files
            };
            var form_data = serialize(
                data,
                options,
            );

            if (typeof this.files != "undefined") {
                this.files.forEach(function (item: any) {
                    // 'file' is the "name" of the form input being uploaded
                    // We explicitly pass the filename as the 3rd argument, as IE has a habit of
                    // incorrectly inferring the file name from the file object
                    form_data.append('files', item, item.name)
                });
            }
            //console.log(data);
            CanzoneDataService.create(form_data)
                .then((response) => {
                    //this.canzone.id = response.data.id;
                    //this.feedbackItem = new FeedbackItem(true, true);
                    this.feedbackshow = true;
                    this.feedbackAlertType = "success";
                    this.feedback = "Canzone " + data.titolo + " creata con successo";
                    this.cleancCanzoneFormGroup();
                    //console.log(response.data);
                },
                    (error) => {
                        this.feedbackshow = true;
                        this.feedbackAlertType = "danger";
                        this.feedback = (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (error.response.status === 401) {
                            this.signOut();
                            this.$router.push("/login");
                        }
                    })
                .catch((e) => {

                    this.feedbackshow = true;
                    this.feedbackAlertType = "danger";
                    this.feedback = e.message;
                    console.log(e);

                    if (e.response.status === 401) {
                        this.signOut();
                        this.$router.push("/login");
                    }
                });
        }
        else {
            this.canzone.titolo = this.canzoneFormGroup.props.titolo;
            this.canzone.autore = this.canzoneFormGroup.props.autore;
            this.canzone.testo = (this.$refs[this.editorCanzoneName] as any).getTestoCanzone(),
                this.canzone.partiDellaMessaIdeali = this.partiDellaMessaValueList;
            this.canzone.tempiLiturgiciIdeali = this.tempiLiturgiciValueList;
            this.canzone.paroleChiave = this.paroleChiaveValueList;
            this.canzone.allegati = this.allegatiComputed;
            this.canzone.visibilityPolicy = this._visibilitaSelected;
            var form_data = serialize(
                this.canzone,
                options,
            );

            if (typeof this.files != "undefined") {
                this.files.forEach(function (item: any) {
                    // 'file' is the "name" of the form input being uploaded
                    // We explicitly pass the filename as the 3rd argument, as IE has a habit of
                    // incorrectly inferring the file name from the file object
                    form_data.append('files', item, item.name)
                });
            }

            CanzoneDataService.update(form_data)
                .then((response) => {
                    //this.feedbackItem = new FeedbackItem(true, true);
                    this.feedbackshow = true;
                    this.feedbackAlertType = "success";
                    this.feedback = "Canzone modificata con successo";
                    //console.log(response.data);
                    //this.message = "The tutorial was updated successfully!";
                },
                    (error) => {
                        this.feedbackshow = true;
                        this.feedbackAlertType = "danger";
                        this.feedback = (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (error.response.status === 401) {
                            this.signOut();
                            this.$router.push("/login");
                        }
                    })
                .catch((e) => {
                    this.feedbackshow = true;
                    this.feedbackAlertType = "danger";
                    this.feedback = e.message;
                    console.log(e);

                    if (e.response.status === 401) {
                        this.signOut();
                        this.$router.push("/login");
                    }
                });
        }
    }

    cleancCanzoneFormGroup() {
        this.canzoneFormGroup.props.titolo = "";
        this.canzoneFormGroup.props.autore = "";
        this.partiDellaMessaValueList = [];
        this.tempiLiturgiciValueList = [];
        this.paroleChiaveValueList = [];
        (this.$refs[this.editorCanzoneName] as any).clearTestoCanzone();
        this.files = [];
        this.allegatiComputed = [];
        (this.$refs['fileInput'] as any).reset();
        //(document.getElementById('fileInput') as HTMLFormElement).reset();
    }

    created() {
        if (typeof this.canzone != "undefined") {
            this.idCanzoneComputed = this.canzone.id;
            this.testoCanzoneComputed = this.canzone.testo;
        }
    }

    mounted() {
        if (typeof this.canzone != "undefined") {
            this.canzoneFormGroup.props.titolo = this.canzone.titolo;
            this.canzoneFormGroup.props.autore = this.canzone.autore;
            this.getAllegatiValueList();
        }

        if (typeof this.allPartiDellaMessa == "undefined" || this.allPartiDellaMessa == []) {
            this.getAllPartiDellaMessa();
        }
        else {
            this.partiDellaMessaList = this.allPartiDellaMessa;
        }
        this.getPartiDellaMessa();
        if (typeof this.allTempiLiturgici == "undefined" || this.allTempiLiturgici == []) {
            this.getAllTempiLiturgici();
        }
        else {
            this.tempiLiturgiciList = this.allTempiLiturgici;
        }
        this.getTempiLiturgiciValueList();
        if (typeof this.allParoleChiave == "undefined" || this.allParoleChiave == []) {
            this.getAllParoleChiave();
        }
        else {
            this.paroleChiaveList = this.allParoleChiave;
        }

        this.getParoleChiaveValueList();
    }
}


