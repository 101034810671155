


















import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';

@Component({
    components: {
        Multiselect
    }
})
export default class MultipleMultiselect extends Vue {
    @Prop({ required: false }) valueList!: MultipleMultiselectItem[];
    @Prop({ required: true }) optionsList!: MultipleMultiselectItem[];
    @Prop({ required: false }) disabled: any;
         
    private values: MultipleMultiselectItem[] = [];
    
    private options: MultipleMultiselectItem[] = this.optionsList != null ? this.optionsList : [];

    get computedValues() { 
        //console.log("get computedValues");
        this.changedSelection();
        return this.values
    }
    set computedValues(newValue: MultipleMultiselectItem[]) {
        this.values = newValue;
        //console.log("set computedValues");
       
       // console.log(newValue);
    }
        

    get computedOptions() {
        //console.log("options-->"+this.options);
        return this.options;
    }
    set computedOptions(newValue: MultipleMultiselectItem[]) {
        this.options = newValue;     
        //console.log(newValue);
    }  

   
    changedSelection() {
        //console.log("changedSelection");
        //console.log(this.values);
        this.$emit("changedSelection", this.values);
    }

    @Watch('valueList')
    onValueListChanged(value: MultipleMultiselectItem[], oldValue: MultipleMultiselectItem[]) {
        this.values = value;
        //console.log("onValueListChanged");
    }



    @Watch('optionsList')
    onOptionsListChanged(value: MultipleMultiselectItem[], oldValue: MultipleMultiselectItem[]) {
        this.options = value;
    }

}


