import http from "../http-common";
import authHeader from '../auth-header';

class TempoLiturgicoDataService {
    //getAll() {
    //    return http.get("/tutorials");
    //}

    async getAll() {
        return await http.get(`/tempoliturgico`, { headers: authHeader() });
    }

    //create(data: any) {
    //    return http.post("/tutorials", data);
    //}

    //update(id: string, data: any) {
    //    return http.put(`/tutorials/${id}`, data);
    //}

    //delete(id: string) {
    //    return http.delete(`/tutorials/${id}`);
    //}

    //deleteAll() {
    //    return http.delete(`/tutorials`);
    //}

    //findByTitle(title: string) {
    //    return http.get(`/tutorials?title=${title}`);
    //}
}

export default new TempoLiturgicoDataService();