import http from "../http-common";
import authHeader from '../auth-header';

class CanzoneDataService {
    //getAll() {
    //    return http.get("/tutorials");
    //}
   
    async get(id: string, withFileStream: boolean = false) {
        return await http.get(`/canzone/${id}/${withFileStream}`, { headers: authHeader() });
    }

    async getAll() {
        return await http.get(`/canzone`, { headers: authHeader() });
    }

    async search(data: any) {
        return await http.post(`/canzone/SearchCanzoni`, data, { headers: authHeader("application/json") });
    }

    async create(data: any) {
        return await http.post("/canzone/CreaCanzone", data, { headers: authHeader() });
    }

    async update(data: any) {
        return await http.put(`/canzone/${data.id}`, data, { headers: authHeader() });
    }

    async delete(id: string) {
        return await http.delete(`/canzone/${id}`, { headers: authHeader() });
    }

    //deleteAll() {
    //    return http.delete(`/tutorials`);
    //}

    //findByTitle(title: string) {
    //    return http.get(`/tutorials?title=${title}`);
    //}
}

export default new CanzoneDataService();