import * as XRegExp from "xregexp";

/**
 * The rank for each possible chord. Rank is the distance in semitones from C.
 */
export const CHORD_RANKS: Map<string, number> = new Map([
    ["SI#", 0],
    ["DO", 0],
    ["DO#", 1],
    ["REb", 1],
    ["RE", 2],
    ["RE#", 3],
    ["MIb", 3],
    ["MI", 4],
    ["FAb", 4],
    ["MI#", 5],
    ["FA", 5],
    ["FA#", 6],
    ["SOLb", 6],
    ["SOL", 7],
    ["SOL#", 8],
    ["LAb", 8],
    ["LA", 9],
    ["LA#", 10],
    ["SIb", 10],
    ["DOb", 11],
    ["SI", 11],
]);

// Regex for recognizing chords
const TRIAD_PATTERN = "(M|maj|major|m|min|minor|dim|sus|dom|aug|\\+|-)";
const ADDED_TONE_PATTERN = "(([/\\.\\+]|add)?\\d+[\\+-]?)";
const SUFFIX_PATTERN = `(?<suffix>\\(?${TRIAD_PATTERN}?${ADDED_TONE_PATTERN}*\\)?)`;
const BASS_PATTERN = "(\\/(?<bass>(DO|RE|MI|FA|SOL|LA|SI)(#|b)?))?";

export const ROOT_PATTERN = "(?<root>(DO|RE|MI|FA|SOL|LA|SI)(#|b)?)";
export const MINOR_PATTERN = "(m|min|minor)+";

const CHORD_REGEX = XRegExp.default(
  `^${ROOT_PATTERN}${SUFFIX_PATTERN}${BASS_PATTERN}$`
);
const MINOR_SUFFIX_REGEX = XRegExp.default(`^${MINOR_PATTERN}.*$`);

/**
 * Represents a musical chord. For example, Am7/C would have:
 *
 * root: A
 * suffix: m7
 * bass: C
 */
export class Chord {
  constructor(
    public root: any,
    public suffix?: any,
    public bass?: any
  ) { }

  toString(): string {
    if (this.bass) {
      return this.root + this.suffix + "/" + this.bass;
    } else {
      return this.root + this.suffix;
    }
  }

  isMinor(): boolean {
    return MINOR_SUFFIX_REGEX.test(this.suffix != null ? this.suffix : "");
  }

  static parse(token: string): Chord {
    if (!isChord(token)) {
      throw new Error(`${token} is not a valid chord`);
    }
    const result = XRegExp.exec(token, CHORD_REGEX);
    if (result == null) {
        throw new Error(`${token} is not a valid chord`);
      }      
    return new Chord(result.root, result.suffix, result.bass);
  }
}

export function isChord(token: string): boolean {
  return CHORD_REGEX.test(token);
}
