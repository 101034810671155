









import { Component, Prop, Watch, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'
import MultipleMultiselectItem from '../model/MultipleMultiselectItem';

@Component({
    components: {            
        Multiselect
    }
})
export default class SingleMultiselect extends Vue {
    @Prop({ required: false }) preselectValue!: any;
    @Prop({ required: true }) readonly optionsList!: MultipleMultiselectItem[];
    @Prop({ required: false }) disabled: any;

    private options: MultipleMultiselectItem[] = this.optionsList != null ? this.optionsList : [];

    get computedOptions() {
        //console.log("options-->"+this.options);
        return this.options;
    }
    set computedOptions(newValue: MultipleMultiselectItem[]) {
        this.options = newValue;
        //console.log(newValue);
    }

    private value: any;

    get computedValue() {
        //console.log("get computedValues");
        this.changedSelection();
        return this.value
    }
    set computedValue(newValue: MultipleMultiselectItem) {
        this.value = newValue;
        //console.log("set computedValues");

        // console.log(newValue);
    }

    changedSelection() {
        //console.log("changedSelection");
        //console.log(this.values);
        this.$emit("changedSelection", this.value);
    }

    @Watch('preselectValue')
    onPreselectValueChanged(value: MultipleMultiselectItem, oldValue: MultipleMultiselectItem) {
        this.value = value;
        //console.log("onValueListChanged");
    }

    @Watch('optionsList')
    onOptionsListChanged(value: MultipleMultiselectItem[], oldValue: MultipleMultiselectItem[]) {
        this.options = value;
    }
}
