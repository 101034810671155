import { Enum, EnumValue } from 'ts-enums'
import { Chord, MINOR_PATTERN, ROOT_PATTERN } from './Chord';
import XRegExp from 'xregexp';

// Chromatic scale starting from C using flats only.
const FLAT_SCALE = [
    "DO",
    "REb",
    "RE",
    "MIb",
    "MI",
    "FA",
    "SOLb",
    "SOL",
    "LAb",
    "LA",
    "SIb",
    "DOb",
];

// Chromatic scale starting from C using sharps only.
const SHARP_SCALE = [
    "DO",
    "DO#",
    "RE",
    "RE#",
    "MI",
    "FA",
    "FA#",
    "SOL",
    "SOL#",
    "LA",
    "LA#",
    "SI",
];

// Chromatic scale for F# major which includes E#.
export const F_SHARP_SCALE = SHARP_SCALE.map(note => note === "FA" ? "MI#" : note);

// Chromatic scale for C# major which includes E# and B#.
export const C_SHARP_SCALE = F_SHARP_SCALE.map(note => note === "DO" ? "SI#" : note);

// Chromatic scale for Gb major which includes Cb.
export const G_FLAT_SCALE = FLAT_SCALE.map(note => note === "SI" ? "DOb" : note);

// Chromatic scale for Cb major which includes Cb and Fb.
export const C_FLAT_SCALE = G_FLAT_SCALE.map(note => note === "MI" ? "FAb" : note);

const KEY_SIGNATURE_REGEX = XRegExp(`${ROOT_PATTERN}(${MINOR_PATTERN})?`)

export enum KeyType { FLAT, SHARP }

export class KeySignature extends EnumValue {
    constructor(public majorKey: string,
        public relativeMinor: string,
        public keyType: KeyType,
        public rank: number,
        public chromaticScale: string[]) {
        super(majorKey);
    }
}

/** Enum for each key signature. */
class KeySignatureEnum extends Enum<KeySignature> {
    DO: KeySignature =
        new KeySignature('DO', 'LAm', KeyType.SHARP, 0, SHARP_SCALE);

    REb: KeySignature =
        new KeySignature('REb', 'SIbm', KeyType.FLAT, 1, FLAT_SCALE);

    RE: KeySignature =
        new KeySignature('RE', 'SIm', KeyType.SHARP, 2, SHARP_SCALE);

    MIb: KeySignature =
        new KeySignature('MIb', 'DOm', KeyType.FLAT, 3, FLAT_SCALE);

    MI: KeySignature =
        new KeySignature('MI', 'DO#m', KeyType.SHARP, 4, SHARP_SCALE);

    FA: KeySignature =
        new KeySignature('FA', 'REm', KeyType.FLAT, 5, FLAT_SCALE);

    SOLb: KeySignature =
        new KeySignature('SOLb', 'MIbm', KeyType.FLAT, 6, G_FLAT_SCALE);

    FAsharp: KeySignature =
        new KeySignature('FA#', 'RE#m', KeyType.SHARP, 6, F_SHARP_SCALE);

    SOL: KeySignature =
        new KeySignature('SOL', 'MIm', KeyType.SHARP, 7, SHARP_SCALE);

    LAb: KeySignature =
        new KeySignature('LAb', 'FAm', KeyType.FLAT, 8, FLAT_SCALE);

    LA: KeySignature =
        new KeySignature('LA', 'FA#m', KeyType.SHARP, 9, SHARP_SCALE);

    SIb: KeySignature =
        new KeySignature('SIb', 'SOLm', KeyType.FLAT, 10, FLAT_SCALE);

    SI: KeySignature =
        new KeySignature('SI', 'SOL#m', KeyType.SHARP, 11, SHARP_SCALE);

    // Unconventional key signatures:

    DOsharp: KeySignature =
        new KeySignature('DO#', 'LA#m', KeyType.SHARP, 1, C_SHARP_SCALE);

    DOb: KeySignature =
        new KeySignature('DOb', 'LAbm', KeyType.FLAT, 11, C_FLAT_SCALE);

    REsharp: KeySignature =
        new KeySignature('RE#', '', KeyType.SHARP, 3, SHARP_SCALE);

    SOLsharp: KeySignature =
        new KeySignature('SOL#', '', KeyType.SHARP, 8, SHARP_SCALE);

    keySignatureMap: Map<string, KeySignature> = new Map();
    rankMap: Map<number, KeySignature> = new Map();

    constructor() {
        super();
        this.initEnum('KeySignature');
        for (const signature of this.values) {
            this.keySignatureMap.set(signature.majorKey, signature);
            this.keySignatureMap.set(signature.relativeMinor, signature);
            if (!this.rankMap.has(signature.rank)) {
                this.rankMap.set(signature.rank, signature);
            }
        }
    }

    /**
     * Returns the enum constant with the specific name or throws an error if the
     * key signature is not valid.
     */
    valueOf(name: string): KeySignature {
        if (KEY_SIGNATURE_REGEX.test(name)) {
            const chord = Chord.parse(name);
            const signatureName = chord.isMinor() ? chord.root + 'm' : chord.root;
            const foundSignature = this.keySignatureMap.get(signatureName);
            if (foundSignature) {
                return foundSignature;
            }

            // If all else fails, try to find any key with this chord in it.
            for (const signature of this.values) {
                if (signature.chromaticScale.includes(chord.root)) {
                    return signature;
                }
            }
        }
        throw new Error(`${name} is not a valid key signature.`);
    }

    forRank(rank: number) {
        const signature = this.rankMap.get(rank);
        if (signature) {
            return signature;
        }
        throw new Error(`${rank} is not a valid rank.`);
    }
}

/**
 * Transforms the given chord into a key signature.
 */
export function guessKeySignature(chord: Chord): KeySignature {
    let signature = chord.root;
    if (chord.isMinor()) {
        signature += 'm';
    }
    return KeySignatures.valueOf(signature);
}

export const KeySignatures: KeySignatureEnum = new KeySignatureEnum();
