

















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect'
import TaggingMultiselectItem from '../model/TaggingMultiselectItem'

@Component({
    components: {
        Multiselect
    }
})
export default class TaggingMultiselect extends Vue {
    @Prop({ required: true }) optionsList!: TaggingMultiselectItem[];
    @Prop({ required: false }) valueList!: TaggingMultiselectItem[];
    @Prop({ required: false }) disabled: any;

    private value: TaggingMultiselectItem[] = [];
    private options: TaggingMultiselectItem[] = this.optionsList != null ? this.optionsList : [];

    get computedValues() {
        this.changedSelection();
        return this.value
    }
    set computedValues(newValue: TaggingMultiselectItem[]) {
        this.value = newValue
    }

    get computedOptions() {
        //console.log("options-->" + this.options);
        return this.options;
    }
    set computedOptions(newValue: TaggingMultiselectItem[]) {
        this.options = newValue
    }

    public addTag(newTag: string): void  { 
          const tag = new TaggingMultiselectItem(newTag);
          this.options.push(tag);
          this.value.push(tag);              
    }      

    changedSelection() {
        //console.log("changedSelection");
        //console.log(this.value);
        this.$emit("changedSelection", this.value);
    }

    @Watch('valueList')
    onValueListChanged(value: TaggingMultiselectItem[], oldValue: TaggingMultiselectItem[]) {
        this.value = value;
    }

    @Watch('optionsList')
    onOptionsListChanged(value: TaggingMultiselectItem[], oldValue: TaggingMultiselectItem[]) {
        this.options = value;
    }
}
